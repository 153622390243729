.printable-area {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 20px;
}

.box {
  height: 300px;
  width: 430px;
  transform: rotate(90deg);
  position: absolute;
}

@media print {
  @page {
    margin: 0px;
    size: A4 portrait;
  }

  .newBox:nth-child(4n + 1) {
    margin-top: 40px;
    margin-left: 30px;
  }

  .newBox:nth-child(4n + 2) {
    margin-top: 40px;
    margin-right: 30px;
  }

  .newBox:nth-child(4n + 3) {
    margin-bottom: 30px;
    margin-left: 30px;
  }

  .newBox:nth-child(4n + 4) {
    margin-bottom: 30px;
    margin-right: 30px;
  }

  .newBox:nth-child(4n + 1) {
    page-break-before: always;
  }
}
