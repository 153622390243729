body {
  font-family: 'Lato';
  position: absolute;
  width: 100%;
  height: 100%;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  overflow-x: hidden;
  font-size: 14px;
  color: #333;
}

#root { height: 100%; }
input:focus { outline-width: 0; }
button:focus { outline-width: 0; }
.ml-20per { margin-left: 20%; }
.sorting-icons .fa-sort-up { transform: translateY(30%); }
.sorting-icons .fa-sort-down { transform: translateY(-14%); }
.modal-overlay {
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  overflow: hidden;
  outline: 0;
  overflow-x: hidden;
  overflow-y: auto;
  background: rgba(0,0,0,.7);
  z-index: 10000;
}
.circle {
  border-radius: 50%;
}
.user-profile-img {
  background-size: cover !important;
  background-repeat: no-repeat !important;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
}
.width-65px { width: 65px; }
.admin-tooltip {
  width: 300px;
  height: 230px;
  padding: 20px 10px;
  border-radius: 4px;
  background: #fff !important;
  border: 1px solid #cfd9e4;
  z-index: 9999999999 !important;
  pointer-events: auto !important;
}
.admin-tooltip:hover {
  visibility: visible !important;
  opacity: 1 !important;
  z-index: 9999999999 !important;
}
.__react_component_tooltip.type-dark.place-bottom:after {
  border-bottom-color: #CFD9E4 !important;
}
.tooltip-circle {
  width: 55px;
  height: 55px;
  background: #f0f7ff;
  border-radius: 50%;
}
.admin-table-row:hover, .admin-staff-table-row:hover {
  background-color: rgb(207, 217, 228, 0.4);
}
.admin-tab-header:hover {
  background-color: rgb(24, 134, 223, 0.1);
  border-bottom: 4px solid #1886DF;
  margin-top: 4px;
  color: #1886DF;
  animation-name: hover-primary-blue;
  animation-duration: 0.3s;
  font-weight: bold;
}
.admin-tab-header {
  width: 85px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes hover-primary-blue {
  from {background-color: white; }
  to {background-color: rgb(24, 134, 223, 0.1); }
}
button.bg-blue-700:hover {
  box-shadow: 0 0 11px rgba(33,33,33,.2);
}
button.red-text-btn:hover {
  background: rgba(211,0,0,0.2);
}
#applicant-work-phone .iti--separate-dial-code {
  width: 72%;
  display: flex;
  justify-content: space-between;
}
#applicant-work-phone .iti__flag-container, #my-settings-work-phone .iti__flag-container {
  position: relative;
  width: 95px;
}
#applicant-work-phone #userPhone, #my-settings-work-phone #userPhone {
  margin-left: 8px !important;
  padding: 8px !important;
}
#applicant-work-phone .iti__selected-flag, #my-settings-work-phone .iti__selected-flag {
  background: white !important;
  border: 1px solid #979797 !important;
  border-radius: 0.25rem !important;
  outline: none !important;
}

#my-settings-work-phone .iti--separate-dial-code {
  width: 70%;
  display: flex;
  justify-content: space-between;
}

.dis .cke_wysiwyg_frame, .dis .cke_wysiwyg_div {
  background-color: #b9b6b6;
  opacity: 0.5;
}

.cke_top {
  background-color:#F2F2F2;
}

.cke_top{
  border-bottom: 1px solid #989898;
}

.cke_chrome {
  border: 1px solid #989898;
}

.dis .cke_top {
  background: #f2f2f2;
}

.dis .cke_chrome {
  border: 1px solid #a9a9a9;
}

.readonly .cke_wysiwyg_frame, .readonly .cke_wysiwyg_div {
  background-color: #b9b6b6;
  opacity: 0.5;
}

.cke_top {
background-color:#F2F2F2;
}

.cke_top{
border-bottom: 1px solid #989898;
}

.cke_chrome {
  border: 1px solid #989898;
}

.readonly .cke_top {
background: #f2f2f2;
}

.readonly .cke_chrome {
border: 1px solid #a9a9a9;
}

.w-20rem {
  width: 20rem;
}