/* .width-230-px { width: 230px; }
.width-100 { width: 100%; }
.m-t-b-15 { margin: 15px 0px; }
.wrapper { position: relative; width: 85%; } */
.logo { background-position: center !important; background-size: cover !important; }
.w-260px { width: 260px }
.ml-260px { margin-left: 260px; }
.small-circle-red { 
    width: 8px;
    height: 8px;
    background: #d30000;
    border-radius: 50%;
}
/* .grey-border-right { border: 1px solid #F0F0F0; }
.tab-container { border-left: 5px solid #007CE3; height: 30px; }
.tab-text{ margin-left: 28%; color: #007CE3; font-weight: bold;}
.aside-wrapper { position: absolute; height: 100%; } */